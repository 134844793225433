/* this file is transformed by vux-loader */
/* eslint-disable */
import "core-js/modules/es.array.push.js";
import axios from "axios";
import { sessionStorage } from "../../../../../assets/myjs/glovar.js";
import { Dialog } from "vant";
export default {
  name: "ywTrackForm",
  data() {
    return {
      ywInfoData: {},
      showGMBSData: false,
      showHKData: false,
      showFKData: false,
      xmbh: "",
      gzdbh: "",
      gzry: "",
      gzrq: "",
      gznr: "",
      filedata: [],
      files: []
    };
  },
  created() {
    this.xmbh = sessionStorage.getItem("xmbh");
  },
  methods: {
    onClickLeft() {
      this.$router.push("/ywLIst");
      sessionStorage.removeItem("xmbh");
    },
    onConfirmGMBS(timestamp) {
      let year = timestamp.getFullYear();
      const month = (timestamp.getMonth() + 1).toString().padStart(2, "0");
      const date = timestamp.getDate().toString().padStart(2, "0");
      this.gmbsrq = year + "-" + month + "-" + date;
      this.showGMBSData = false;
    },
    onConfirmHK(timestamp) {
      let year = timestamp.getFullYear();
      const month = (timestamp.getMonth() + 1).toString().padStart(2, "0");
      const date = timestamp.getDate().toString().padStart(2, "0");
      this.fkrq = year + "-" + month + "-" + date;
      this.showHKData = false;
    },
    onConfirmFK(timestamp) {
      let year = timestamp.getFullYear();
      const month = (timestamp.getMonth() + 1).toString().padStart(2, "0");
      const date = timestamp.getDate().toString().padStart(2, "0");
      this.gzrq = year + "-" + month + "-" + date;
      this.showFKData = false;
    },
    onSubmit() {
      var params = new FormData();
      params.append("ywbh", this.xmbh);
      params.append("gzdbh", this.gzdbh);
      params.append("gzry", this.gzry);
      params.append("gzrq", this.gzrq);
      params.append("gznr", this.gznr);
      let filedata = this.filedata;
      for (let i = 0; i < filedata.length; i++) {
        params.append("files", filedata[i].file);
        this.files.push(filedata[i].file);
        console.log(this.files[i].file);
      }
      // params.append("files", this.files);
      var url = _baseUrl + `/ywgz/ywgz/ywGz/addYwGzxx.do`;
      axios.post(url, params).then(res => {
        // sessionStorage.removeItem("ywdata");
        if (res.data.message == "添加成功！") {
          Dialog.alert({
            title: "提示",
            message: "跟踪成功！"
          }).then(() => {
            this.$router.push("/ywProject");
            sessionStorage.removeItem("xmbh");
          });
        }
        console.log(res);
      });
    }
  }
};